<template>
  <div class="flex bubbleProject">
    <el-container>
      <el-container>
        <el-aside>
          <div v-for="(item, index) in typeList" :key="index">
            <div
              class="flex flex-between items-center bubbleProject-ee"
              @click="buttonType(item, index)"
            >
              <div class="bubbleProject-title">{{ item.name }}</div>
              <i
                :class="item.unfold ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                style="font-size: 16px; color: #b1b1b1"
                v-if="item.acheintheskull"
              ></i>
            </div>
            <div class="bubbleProject-type flex">
              <div class="bubbleProject-border">
                <div
                  class="bubbleProject-border-typeindex"
                  :style="{
                    transform: 'translatey(' + typeIndex * 56 + 'px)',
                    top: 0 + 'px',
                  }"
                ></div>
              </div>
              <div
                class="bubbleProject-type-ul"
                v-if="item.unfold && item.acheintheskull"
              >
                <!-- <div>
                  <div
                    v-for="(l, i) in item.secondlevel"
                    :key="i"
                    class="bubbleProject-type-ul-li"
                    :class="'checkornot' + index + i"
                    @click="secondlevelButton(index, i)"
                  >
                    {{ l.name }}
                  </div>
                </div> -->
                <div>
                  <div
                    v-for="(l, i) in item.secondlevel"
                    :key="i"
                    @click="secondlevelButton(item, index, i)"
                  >
                    <div
                      class="bubbleProject-type-ul-li flex flex-between items-center"
                      :class="'checkornot' + index + i"
                      style="padding-right: 0"
                    >
                      <div>{{ l.name }}</div>
                      <i
                        :class="
                          l.unfold ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                        "
                        style="font-size: 16px; color: #b1b1b1"
                        v-if="l.threelevel.length > 0"
                      ></i>
                    </div>
                    <template v-if="l.threelevel.length > 0">
                      <template v-if="l.unfold">
                        <div
                          v-for="(w, q) in l.threelevel"
                          :key="q"
                          class="bubbleProject-type-ul-li"
                          :class="'checkornot' + index + i + q"
                          style="padding-left: 60px"
                          @click.stop="threelevelw(item, w, index, i, q)"
                        >
                          {{ w.name }}
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-aside>
        <el-container>
          <el-main>
            <div class="bubbleProject-butom">
              <div class="bubbleProject-content">
                <template v-if="threelevel == null">
                  <div class="bubbleProject-content-title">
                    {{ typeList[typeIndex].secondlevel[secondlevelIndex].name }}
                  </div>
                </template>
                <template v-else>
                  <div class="bubbleProject-content-title">
                    {{
                      typeList[typeIndex].secondlevel[secondlevelIndex]
                        .threelevel[threelevel].name
                    }}
                  </div>
                </template>
              </div>
              <div>
                <template v-if="threelevel == null">
                  <el-image
                    style="width: 100%"
                    :src="typeList[typeIndex].secondlevel[secondlevelIndex].img"
                    :class="
                      typeIndex == 0 && secondlevelIndex == 2
                        ? 'img977'
                        : typeIndex == 0 && secondlevelIndex == 4
                        ? 'img970'
                        : typeIndex == 1 && secondlevelIndex == 0
                        ? 'img981'
                        : ''
                    "
                  ></el-image>
                </template>
                <template v-else>
                  <el-image
                    style="width: 100%"
                    :src="
                      typeList[typeIndex].secondlevel[secondlevelIndex]
                        .threelevel[threelevel].img
                    "
                  ></el-image>
                </template>
              </div>
              <!-- <div
                class="bubbleProject-buttom flex flex-between items-center"
                v-if="typeIndex == 0"
                @click="typeIndex = typeIndex + 1"
              >
                <div class="bubbleProject-buttom-one">
                  <div class="bubbleProject-buttom-one-next">Next</div>
                  <div class="bubbleProject-buttom-one-name">
                    {{ typeList[typeIndex + 1][].name }}
                  </div>
                </div>
                <i
                  class="el-icon-arrow-right"
                  style="font-size: 16px; color: #b1b1b1"
                ></i>
              </div>
              <div
                v-else-if="typeIndex + 1 == typeList.length"
                class="bubbleProject-buttom flex flex-between items-center border32"
                @click="typeIndex = typeIndex - 1"
              >
                <i
                  class="el-icon-arrow-left"
                  style="font-size: 16px; color: #b1b1b1"
                ></i>
                <div class="bubbleProject-buttom-one">
                  <div class="bubbleProject-buttom-one-next">Next</div>
                  <div class="bubbleProject-buttom-one-name">
                    {{ typeList[typeIndex - 1].name }}
                  </div>
                </div>
              </div>
              <div v-else class="flex bubbleProject-centre flex-between">
                <div
                  class="bubbleProject-centre-button flex flex-between items-center border32"
                  @click="typeIndex = typeIndex - 1"
                >
                  <i
                    class="el-icon-arrow-left"
                    style="font-size: 16px; color: #b1b1b1"
                  ></i>
                  <div class="bubbleProject-centre-button-one">
                    <div class="bubbleProject-centre-button-one-next">Next</div>
                    <div class="bubbleProject-centre-button-one-name">
                      {{ typeList[typeIndex - 1].name }}
                    </div>
                  </div>
                </div>
                <div
                  class="bubbleProject-centre-button flex flex-between items-center"
                  @click="typeIndex = typeIndex + 1"
                >
                  <div class="bubbleProject-centre-button-one">
                    <div class="bubbleProject-centre-button-one-next">Next</div>
                    <div class="bubbleProject-centre-button-one-name">
                      {{ typeList[typeIndex + 1].name }}
                    </div>
                  </div>
                  <i
                    class="el-icon-arrow-right"
                    style="font-size: 16px; color: #b1b1b1"
                  ></i>
                </div>
              </div> -->
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      typeIndex: 0,
      typeIndexNum: 0,
      secondlevelIndex: 0,
      threelevel: null,
      secondlevelIndexNew: 0,
      typeList: [
        {
          typeIndex: 0,
          name: "泡泡计划",
          unfold: false,
          acheintheskull: true,
          secondlevel: [
            {
              secondlevelIndex: 0,
              name: "使命与愿景",
              img: "https://cdn.bubbleplan.cn/static/textImg/MissionandVision.png",
              threelevel: [],
            },
            {
              secondlevelIndex: 1,
              name: "介绍",
              img: "https://cdn.bubbleplan.cn/static/textImg/introduce.png",
              threelevel: [],
            },
            {
              secondlevelIndex: 2,
              name: "种族",
              img: "https://cdn.bubbleplan.cn/static/textImg/race.png",
              threelevel: [],
            },
            {
              secondlevelIndex: 3,
              name: "天赋",
              img: "https://cdn.bubbleplan.cn/static/textImg/talent.png",
              threelevel: [],
            },
            {
              secondlevelIndex: 4,
              name: "游戏功能",
              img: "https://cdn.bubbleplan.cn/static/textImg/Gamefunction.png",
              threelevel: [],
            },
            {
              secondlevelIndex: 5,
              name: "疯狂派",
              img: "https://cdn.bubbleplan.cn/static/textImg/crazies.png",
              threelevel: [],
            },
            {
              secondlevelIndex: 6,
              name: "NFT交易市场",
              img: "https://cdn.bubbleplan.cn/static/textImg/NFTtransaction.png",
              threelevel: [],
            },
          ],
        },
        {
          typeIndex: 1,
          name: "路线图",
          secondlevel: [
            {
              secondlevelIndex: 0,
              name: "路线图",
              img: "https://cdn.bubbleplan.cn/static/textImg/roadmap.png",
              threelevel: [],
            },
          ],
          unfold: false,
          acheintheskull: false,
        },
        {
          typeIndex: 2,
          name: "生态环境",
          unfold: false,
          acheintheskull: true,
          secondlevel: [
            {
              secondlevelIndex: 0,
              name: "游戏经济",
              img: "https://cdn.bubbleplan.cn/static/textImg/Gameeconomy.png",
              unfold: false,
              threelevel: [
                {
                  secondlevelIndex: 0,
                  name: "通行证",
                  img: "https://cdn.bubbleplan.cn/static/textImg/trafficpermit.png",
                },
                {
                  secondlevelIndex: 0,
                  name: "任务系统",
                  img: "https://cdn.bubbleplan.cn/static/textImg/missionsystem.png",
                },
                {
                  secondlevelIndex: 0,
                  name: "竞技场",
                  img: "https://cdn.bubbleplan.cn/static/textImg/arena.png",
                },
                {
                  secondlevelIndex: 0,
                  name: "租赁",
                  img: "https://cdn.bubbleplan.cn/static/textImg/lease.png",
                },
                {
                  secondlevelIndex: 0,
                  name: "疯狂派的秘密实验室",
                  img: "https://cdn.bubbleplan.cn/static/textImg/Crazyssecretlab.png",
                },
              ],
            },
            {
              secondlevelIndex: 0,
              name: "DEFI经济",
              img: "https://cdn.bubbleplan.cn/static/textImg/DEF.png",
              threelevel: [],
            },
          ],
        },
        {
          typeIndex: 3,
          name: "项目文化",
          unfold: false,
          acheintheskull: true,
          secondlevel: [
            {
              secondlevelIndex: 0,
              name: "核心价值观",
              img: "https://cdn.bubbleplan.cn/static/textImg/corevalue.png",
              threelevel: [],
            },
            {
              secondlevelIndex: 0,
              name: "社会责任",
              img: "https://cdn.bubbleplan.cn/static/textImg/socialresponsibility.png",
              threelevel: [],
            },
          ],
        },
      ],
    };
  },
  methods: {
    // 一级切换
    buttonType(item, index) {
      this.typeIndexNum = this.typeList[index].typeIndexNum;
      if (index == 1) {
        this.secondlevelIndex = 0;
        const imgS = document.querySelectorAll(".bubbleProject-type-ul-li");
        //   imgS[index].classList.remove("bubbleProject-type-ul-border");
        imgS.forEach((el) => {
          el.classList.remove("bubbleProject-type-ul-border");
        });
      }
      this.secondlevelIndex = 0;
      this.threelevel = null;
      this.typeIndex = index;
      this.typeList[index].unfold = !this.typeList[index].unfold;
    },
    //二级切换
    secondlevelButton(item, index, i) {
      this.typeIndex = item.typeIndex;
      this.threelevel = null;
      if (index == 2 && i == 0) {
        this.typeList[index].secondlevel[i].unfold =
          !this.typeList[index].secondlevel[i].unfold;
      }
      const imgS = document.querySelectorAll(".bubbleProject-type-ul-li");
      const imgw = document.querySelector(".checkornot" + index + i);

      //   imgS[index].classList.remove("bubbleProject-type-ul-border");
      imgS.forEach((el) => {
        if (el.classList) {
          el.classList.remove("bubbleProject-type-ul-border");
        }
      });
      imgw.classList.add("bubbleProject-type-ul-border");
      this.secondlevelIndex = i;
    },
    //三级切换
    threelevelw(item, w, index, i, q) {
      this.typeIndex = item.typeIndex;
      this.secondlevelIndex = w.secondlevelIndex;
      const imgS = document.querySelectorAll(".bubbleProject-type-ul-li");
      const imgw = document.querySelector(".checkornot" + index + i + q);
      imgS.forEach((el) => {
        if (el.classList) {
          el.classList.remove("bubbleProject-type-ul-border");
        }
      });
      imgw.classList.add("bubbleProject-type-ul-border");
      this.threelevel = q;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.bubbleProject {
  padding-left: 221px;
  padding-top: 70px;
  background: #fff;
  height: calc(100vh - 94px);
  :deep(.el-aside) {
    width: 254px !important;
  }
  :deep(.el-aside::-webkit-scrollbar) {
    width: 0px;
    height: 0px;
  }
  :deep(.el-main::-webkit-scrollbar) {
    width: 0px;
    height: 0px;
  }
  &-title {
    color: #5d5d5d;
    font-size: 20px;
    margin-top: 8px;
    // font-weight: 700;
  }
  .bubbleProject-ee:hover {
    cursor: pointer;
  }

  &-border {
    width: 1px;
    background: #dbdbdb;
    position: relative;
    // &-typeindex {
    //   width: 1px;
    //   height: 26px;
    //   background: #346ddb;
    //   position: absolute;
    //   left: 0;
    //   transition-duration: 300ms;
    // }
  }
  &-type {
    margin-top: 20px;
    &-ul {
      width: 100%;
      &-li:hover {
        cursor: pointer;
        background: #f6f6f6;
        border-radius: 8px;
      }
      &-li {
        width: 100%;
        height: 41px;
        line-height: 41px;
        color: #5d5d5d;
        font-size: 18px;
        margin-bottom: 11px;
        padding: 0 31px;
      }
      &-border {
        background: #e6edfb !important;
        color: #346ddb !important;
      }
    }
  }
  &-content {
    margin-bottom: 20px;
    &-title {
      color: #000000;
      font-size: 34px;
      font-weight: 700;
    }
  }
  &-buttom:hover {
    cursor: pointer;
  }
  .border32 {
    padding-left: 32px;
  }
  &-centre {
    margin-top: 60px;

    &-button:hover {
      cursor: pointer;
    }
    &-button {
      width: 462px;
      height: 89px;
      border-radius: 6px;
      border: 1px solid #e4e4e4;
      padding: 0 32px 0 22px;

      &-one {
        &-next {
          color: #595c59;
          font-size: 16px;
        }
        &-name {
          margin-top: 8px;
          color: #1f1f1f;
          font-size: 19px;
        }
      }
    }
  }
  &-buttom {
    width: 100%;
    height: 89px;
    border-radius: 6px;
    border: 1px solid #e4e4e4;
    margin-top: 60px;
    padding: 0 32px 0 22px;
    &-one {
      &-next {
        color: #595c59;
        font-size: 16px;
      }
      &-name {
        margin-top: 8px;
        color: #1f1f1f;
        font-size: 19px;
      }
    }
  }
  &-butom {
    width: 938px;
    margin-left: 110px;
  }
  .img977 {
    width: 977px !important;
  }
  .img970 {
    width: 970px !important;
  }
  .img981 {
    width: 981px !important;
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex bubbleProject"},[_c('el-container',[_c('el-container',[_c('el-aside',_vm._l((_vm.typeList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"flex flex-between items-center bubbleProject-ee",on:{"click":function($event){return _vm.buttonType(item, index)}}},[_c('div',{staticClass:"bubbleProject-title"},[_vm._v(_vm._s(item.name))]),(item.acheintheskull)?_c('i',{class:item.unfold ? 'el-icon-arrow-up' : 'el-icon-arrow-down',staticStyle:{"font-size":"16px","color":"#b1b1b1"}}):_vm._e()]),_c('div',{staticClass:"bubbleProject-type flex"},[_c('div',{staticClass:"bubbleProject-border"},[_c('div',{staticClass:"bubbleProject-border-typeindex",style:({
                  transform: 'translatey(' + _vm.typeIndex * 56 + 'px)',
                  top: 0 + 'px',
                })})]),(item.unfold && item.acheintheskull)?_c('div',{staticClass:"bubbleProject-type-ul"},[_c('div',_vm._l((item.secondlevel),function(l,i){return _c('div',{key:i,on:{"click":function($event){return _vm.secondlevelButton(item, index, i)}}},[_c('div',{staticClass:"bubbleProject-type-ul-li flex flex-between items-center",class:'checkornot' + index + i,staticStyle:{"padding-right":"0"}},[_c('div',[_vm._v(_vm._s(l.name))]),(l.threelevel.length > 0)?_c('i',{class:l.unfold ? 'el-icon-arrow-up' : 'el-icon-arrow-down',staticStyle:{"font-size":"16px","color":"#b1b1b1"}}):_vm._e()]),(l.threelevel.length > 0)?[(l.unfold)?_vm._l((l.threelevel),function(w,q){return _c('div',{key:q,staticClass:"bubbleProject-type-ul-li",class:'checkornot' + index + i + q,staticStyle:{"padding-left":"60px"},on:{"click":function($event){$event.stopPropagation();return _vm.threelevelw(item, w, index, i, q)}}},[_vm._v(" "+_vm._s(w.name)+" ")])}):_vm._e()]:_vm._e()],2)}),0)]):_vm._e()])])}),0),_c('el-container',[_c('el-main',[_c('div',{staticClass:"bubbleProject-butom"},[_c('div',{staticClass:"bubbleProject-content"},[(_vm.threelevel == null)?[_c('div',{staticClass:"bubbleProject-content-title"},[_vm._v(" "+_vm._s(_vm.typeList[_vm.typeIndex].secondlevel[_vm.secondlevelIndex].name)+" ")])]:[_c('div',{staticClass:"bubbleProject-content-title"},[_vm._v(" "+_vm._s(_vm.typeList[_vm.typeIndex].secondlevel[_vm.secondlevelIndex] .threelevel[_vm.threelevel].name)+" ")])]],2),_c('div',[(_vm.threelevel == null)?[_c('el-image',{class:_vm.typeIndex == 0 && _vm.secondlevelIndex == 2
                      ? 'img977'
                      : _vm.typeIndex == 0 && _vm.secondlevelIndex == 4
                      ? 'img970'
                      : _vm.typeIndex == 1 && _vm.secondlevelIndex == 0
                      ? 'img981'
                      : '',staticStyle:{"width":"100%"},attrs:{"src":_vm.typeList[_vm.typeIndex].secondlevel[_vm.secondlevelIndex].img}})]:[_c('el-image',{staticStyle:{"width":"100%"},attrs:{"src":_vm.typeList[_vm.typeIndex].secondlevel[_vm.secondlevelIndex]
                      .threelevel[_vm.threelevel].img}})]],2)])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }